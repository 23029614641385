import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import { screen } from "../components/common/variables";
import { getVimeoId } from "../components/helpers";
import GetInTouch from "../components/get-in-touch";
import Number from "../images/svg/number-404.svg";

const Wrapper = styled.div`
  padding-top: 240px;
  padding-bottom: 176px;
  @media ${screen.xsmall} {
    padding-top: 300px;
    padding-bottom: 300px;
  }
  @media ${screen.small} {
    padding-top: 80px;
    padding-bottom: 170px;
  }
  @media ${screen.xlarge} {
    padding-bottom: 200px;
    min-height: 1100px;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-left: 35px;
    padding-right: 35px;
    @media ${screen.xsmall} {
      padding-left: 70px;
      padding-right: 70px;
    }
    @media ${screen.small} {
      padding-top: 82px;
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.medium} {
      padding-top: 100px;
      padding-left: 160px;
      padding-right: 160px;
    }
    @media ${screen.xlarge} {
      padding-top: 140px;
      padding-left: 280px;
      padding-right: 280px;
    }

    &__text {
      width: 100%;
      position: relative;
      z-index: 2;
      @media ${screen.xsmall} {
        max-width: 510px;
        margin-right: 100px;
      }

      a {
        display: none;
      }
    }

    &__video {
      width: 100%;
      position: relative;
      z-index: 2;
      display: none;
      @media ${screen.small} {
        display: block;
      }

      .iframe-wrapper {
        position: relative;
        padding-top: 56.25%;
        iframe {
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    &__number-bg {
      display: block;
      max-width: 250px;
      position: absolute;
      width: 100%;
      top: -90px;
      left: 100px;
      z-index: 1;
      @media ${screen.xsmall} {
        left: 230px;
        top: -130px;
        max-width: 340px;
      }
      @media ${screen.small} {
        left: 250px;
        top: 100px;
        max-width: 390px;
      }
      @media ${screen.medium} {
        left: 450px;
        top: 40px;
        max-width: 430px;
      }
    }
  }
`;

const NotFoundPage = () => {
  const videoUrl = "https://vimeo.com/62482262";
  return (
    <Layout>
      <SEO title="Sorry Page Not found | Australian Thermic Lance Company" />
      <Wrapper>
        <div className="content">
          <div className="content__text">
            <GetInTouch
              heading={`We couldn’t find what you’re looking for.`}
              subheading={`But you can watch a Thermic Lance in action!`}
              linkLabel={`OVER HERE`}
              link={`/insights`}
            />
          </div>
          <div className="content__video">
            <div className="iframe-wrapper">
              <iframe
                title={`vimeo-${getVimeoId(videoUrl)}`}
                src={`https://player.vimeo.com/video/${getVimeoId(videoUrl)}`}
                frameBorder="0"
                allowFullScreen
              />
            </div>
          </div>

          <span className="content__number-bg">
            <Number />
          </span>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default NotFoundPage;
